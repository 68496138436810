import { Box } from '@chakra-ui/react';

function DisabledOverlay() {
  return (
    <Box
      position={'absolute'}
      top={0}
      left={0}
      right={0}
      bottom={0}
      bg="white"
      opacity={0.7}
      zIndex={9999}
      cursor={'not-allowed'}
    ></Box>
  );
}

export default DisabledOverlay;
