import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import { renderInputElement } from 'helpers/formHelper';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';

const InstallmentSettings = ({
  fields,
  onFieldChange,
  control,
  isPageDisabled,
}) => {
  return (
    <SimpleGrid
      w={'max'}
      gridTemplateColumns={'min-content min-content min-content min-content'}
      whiteSpace={'nowrap'}
      justifyContent={'space-between'}
      spacingY={4}
      spacingX={10}
      alignItems={'center'}
      fontSize={'sm'}
    >
      {fields.map((item, index) => (
        <Fragment key={index}>
          {item.isHidden ? null : item.isCustomField ? (
            item.element
          ) : (
            <>
              <Text>{item.label}</Text>
              <Box w={200}>
                <Controller
                  control={control}
                  name={item.name}
                  render={renderInputElement({
                    item,
                    styleProps: { fontWeight: 800, w: 200 },
                    showPrefix: true,
                    isDisabled: isPageDisabled,
                    onChange: (name, value) => {
                      onFieldChange(name, value);
                    },
                  })}
                />
              </Box>
              <Box></Box>
              <Box></Box>
            </>
          )}
        </Fragment>
      ))}
    </SimpleGrid>
  );
};

InstallmentSettings.propTypes = {
  fields: PropTypes.array,
  onFieldChange: PropTypes.func,
  control: PropTypes.object,
  isPageDisabled: PropTypes.bool,
};

export default InstallmentSettings;
