import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Number, Percentage } from 'components/Number';
import { HelperTooltip } from 'components/Tooltip';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';

const KPIS_CELL_BG_COLOR = 'twelve.green.300';

const SECTION_KEY = {
  KPIS: 'kpis',
  PROFIT_AND_LOSS: 'profit_and_loss',
  TWELVE_BALANCE: 'twelve_balance',
  CASH_FLOW: 'cash_flow',
  BALANCE_SHEET: 'balance_sheet',
};

const ResultsTable = forwardRef(
  ({ modelResults, cohortsRawData, roasVector, kpiVectors }, ref) => {
    const [expanded, setExpanded] = useState({});

    const borderColor = useColorModeValue(
      'secondaryGray.900',
      'whiteAlpha.100'
    );

    const numberOfColumns = modelResults.profit_and_loss.cogs.length;

    const tableData = { ...modelResults, ...{ kpis: kpiVectors } };

    useImperativeHandle(
      ref,
      () => {
        return {
          generateCsvData,
        };
      },
      []
    );

    const kpis = [
      {
        label: 'KPIs',
        isTitle: true,
        expandId: 'kpis',
      },
      {
        label: 'Exposure To Cash',
        propName: 'exposure_to_cash',
        isPercentage: true,
        depth: 1,
        hide: !expanded.kpis,
      },
      {
        label: 'Exposure To Monthly Revenue',
        propName: 'exposure_to_monthly_revenue',
        isPercentage: true,
        depth: 1,
        hide: !expanded.kpis,
      },
      {
        label: 'Exposure To Run Rate',
        propName: 'exposure_to_run_rate',
        isPercentage: true,
        depth: 1,
        hide: !expanded.kpis,
      },
      {
        label: 'Exposure To Runoff',
        propName: 'exposure_to_runoff',
        isPercentage: true,
        depth: 1,
        hide: !expanded.kpis,
      },
      {
        label: 'Repayments To Cashflow',
        propName: 'repayments_to_cashflow',
        isPercentage: true,
        depth: 1,
        hide: !expanded.kpis,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
    ];

    const profitAndLossConfig = [
      { label: 'Income Statement', isTitle: true },
      {
        label: 'Revenue from Existing Cohorts',
        propName: 'revenue_from_existing_cohorts',
        depth: 1,
        tooltip:
          'For the actual dates we display the revenue from older cohorts and for prediction we show all revenue for actual cohorts.',
      },
      {
        label: 'Revenue form Newest Cohort',
        propName: 'revenue_from_new_cohorts',
        depth: 1,
        tooltip:
          'For the actual dates we display the revenue from the newest cohort, current month, and for prediction we show revenue for all predicted cohorts.',
      },
      {
        label: 'Total Revenue',
        propName: 'total_revenue',
        expandId: 'total_revenue',
        depth: 1,
        isBold: true,
      },
      {
        label: 'Accounting - Revenue',
        propName: 'accounting_revenue',
        depth: 2,
        hide: !expanded.total_revenue,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
      {
        label: 'COGS',
        propName: 'cogs',
        depth: 1,
        tooltip:
          'Excluding Direct Marketing, for prediction we take: (Total Revenue - Gross Profit)*-1',
      },
      {
        label: 'Gross Profit',
        propName: 'gross_profit',
        depth: 1,
        tooltip: 'For prediction we take Total Revenue * Gross Profit Margin%',
        isBold: true,
      },
      {
        label: 'Gross Profit Margin',
        propName: 'gross_profit_margin',
        isPercentage: true,
        depth: 1,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
      {
        label: 'Direct Marketing Spend',
        expandId: 'direct_marketing_spend',
        propName: 'direct_marketing_spend',
        depth: 1,
      },
      {
        label: 'Accounting - Direct Marketing Spend',
        propName: 'accounting_direct_marketing_spend',
        depth: 2,
        hide: !expanded.direct_marketing_spend,
      },
      {
        label: 'Contribution',
        propName: 'contribution',
        depth: 1,
        isBold: true,
      },
      {
        label: 'Contribution Margin',
        propName: 'contribution_margin',
        isPercentage: true,
        depth: 1,
        tooltip: 'Contribution / Total Revenue',
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
      {
        label: 'Payroll',
        propName: 'payroll',
        depth: 1,
        tooltip:
          'For prediction, we use the most recent actual data point available.',
      },
      {
        label: 'Overhead',
        propName: 'overhead',
        depth: 1,
        tooltip:
          'For prediction, we use the most recent actual data point available.',
      },
      {
        isDivider: true,
      },
      {
        label: 'Total Opex',
        propName: 'total_opex',
        depth: 1,
        isBold: true,
      },
      {
        label: 'Opex (% of Total revenue)',
        propName: 'total_opex_to_total_revenue',
        isPercentage: true,
        depth: 1,
        tooltip: 'Total Opex / Total Revenue',
      },
      {
        isEmptyRow: true,
      },
      {
        label: 'EBITDA',
        propName: 'ebitda',
        depth: 1,
        isBold: true,
      },
      {
        label: '% EBITDA',
        propName: 'ebitda_to_total_revenue',
        isPercentage: true,
        depth: 1,
        tooltip: 'EBITDA / Total Revenue',
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
      {
        label: 'Non-Operating Expenses',
        propName: 'non_operating_expenses',
        depth: 1,
      },
      {
        label: 'Non-Operating Income',
        propName: 'non_operating_income',
        depth: 1,
      },
      {
        label: 'Net Income',
        propName: 'net_income',
        depth: 1,
        isBold: true,
        expandId: 'net_income',
      },
      {
        label: 'Accounting - Net Income',
        propName: 'accounting_net_income',
        depth: 2,
        hide: !expanded.net_income,
      },
      {
        label: '% Net Income',
        propName: 'net_income_to_total_revenue',
        isPercentage: true,
        depth: 1,
        tooltip: 'Net Income / Total Revenue',
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
    ];

    const twelveBalanceConfig = [
      { label: 'Twelve Balance', isTitle: true },
      {
        label: 'Beginning Balance',
        propName: 'beginning_balance',
        depth: 1,
        tooltip:
          "Twelve's total exposure, including fees, at the beginning of the month",
        isBold: true,
      },
      {
        label: 'Draw',
        propName: 'draw',
        depth: 1,
        tooltip: 'The amount Twelve financed this month',
      },
      {
        label: 'Fee',
        propName: 'fee',
        depth: 1,
      },
      {
        label: 'Repayment',
        propName: 'repayment',
        depth: 1,
        tooltip: 'The amount the customer repaid this month',
      },
      {
        label: 'Ending Balance',
        propName: 'ending_balance',
        depth: 1,
        tooltip:
          "Twelve's total exposure, including fees, at the end of the month.",
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
    ];

    const cashflowConfig = [
      { label: 'Cash Flow', isTitle: true, expandId: 'cash_flow' },
      {
        label: 'Operating Activities',
        isTitle: true,
        depth: 1,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Net Income',
        propName: 'net_income',
        depth: 2,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Adjustments to Net Income',
        isTitle: true,
        depth: 2,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Accounts Receivable',
        propName: 'changes_in_accounts_receivable',
        depth: 3,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Other Current Asset',
        propName: 'changes_in_other_current_asset',
        depth: 3,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Accounts Payable',
        propName: 'changes_in_accounts_payable',
        depth: 3,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Other Current Liabilities',
        propName: 'changes_in_other_current_liabilities',
        depth: 3,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Total Adjustments to Net Income',
        propName: 'total_adjustments_to_net_income',
        depth: 3,
        hide: !expanded.cash_flow,
        isBold: true,
      },
      {
        label: 'Total Cash from Operating Activities (A)',
        propName: 'total_cash_from_operating_activities',
        depth: 3,
        hide: !expanded.cash_flow,
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Total Cash from Investing Activities (B)',
        propName: 'total_cash_from_investing_activities',
        depth: 3,
        hide: !expanded.cash_flow,
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Financing Activities',
        isTitle: true,
        depth: 1,
      },
      {
        label: 'Changes in Twelve Financing',
        propName: 'changes_in_twelve_financing',
        depth: 2,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Other Short Term Debt',
        propName: 'changes_in_other_short_term_debt',
        depth: 2,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Changes in Equity',
        propName: 'changes_in_equity',
        depth: 2,
        hide: !expanded.cash_flow,
      },
      {
        isEmptyRow: true,
        hide: !expanded.cash_flow,
      },
      {
        label: 'Total Cash from Financing Activities (C)',
        propName: 'total_cash_from_financing_activities',
        depth: 2,
        hide: !expanded.cash_flow,
        isBold: true,
      },
      {
        label: 'Total Change in Cash',
        propName: 'total_change_in_cash',
        depth: 2,
        isBold: true,
      },
      {
        isEmptyRow: true,
      },
      {
        label: 'Cash at Beginning of Period',
        propName: 'cash_at_beginning_of_period',
        depth: 2,
        isBold: true,
      },
      {
        label: 'Cash at End of Period',
        propName: 'cash_at_end_of_period',
        depth: 2,
        isBold: true,
      },
      {
        label: 'Other',
        propName: 'other',
        depth: 2,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
    ];

    const balanceSheetConfig = [
      { label: 'Balance Sheet', isTitle: true, expandId: 'balance_sheet' },
      { label: 'Assets', isTitle: true, depth: 1 },
      { label: 'Cash', propName: 'cash', depth: 2 },
      {
        label: 'Accounts Receivable',
        propName: 'accounts_receivable',
        depth: 2,
        tooltip:
          'For prediction, we calculate the average of the last 3 months’ Accounts Receivable as a percentage of Total Revenue, then multiply this percentage by the current Total Revenue.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Other Current Asset',
        propName: 'other_current_asset',
        depth: 2,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Current Assets',
        propName: 'total_current_assets',
        depth: 2,
        isBold: true,
        hide: !expanded.balance_sheet,
      },
      {
        isDivider: true,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Other Long Term Assets',
        propName: 'other_long_term_assets',
        depth: 2,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.”',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Assets',
        propName: 'total_assets',
        depth: 2,
        isBold: true,
      },
      {
        isEmptyRow: true,
        isDivider: true,
      },
      {
        label: 'Liabilities & Equity',
        isTitle: true,
        depth: 1,
      },
      {
        label: 'Current Liabilities',
        isTitle: true,
        depth: 2,
      },
      {
        label: 'Accounts Payable',
        propName: 'accounts_payable',
        depth: 3,
        tooltip:
          'For prediction, we calculate the average of the last 3 months’ Accounts Payable as a percentage of Total Expense, then multiply this percentage by the current Total Expense. (Total Expense: Total Opex + COGS).',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Twelve Financing',
        propName: 'twelve_financing',
        depth: 3,
      },
      {
        label: 'Other Short Term Debt',
        propName: 'other_short_term_debt',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Other Current Liability',
        propName: 'other_current_liability',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Current Liabilities',
        propName: 'total_current_liabilities',
        depth: 3,
        isBold: true,
        hide: !expanded.balance_sheet,
      },
      {
        isDivider: true,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Long Term Liabilities',
        isTitle: true,
        depth: 2,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Long Term Debt',
        propName: 'long_term_debt',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Other Long Term Liability',
        propName: 'other_long_term_liability',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Long Term Liabilities',
        propName: 'total_long_term_liabilities',
        depth: 3,
        isBold: true,
        hide: !expanded.balance_sheet,
      },
      {
        isEmptyRow: true,
        isDivider: true,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Equity',
        isTitle: true,
        depth: 2,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Common / Pref Equity / Safe',
        propName: 'common_pref_equity_safe',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Retained Earnings / Loss',
        propName: 'retained_earnings_or_loss',
        depth: 3,
        tooltip:
          'For prediction, we take the average of the last 3 actual months.',
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Equity',
        propName: 'total_equity',
        depth: 3,
        isBold: true,
        isUnderline: true,
        hide: !expanded.balance_sheet,
      },
      {
        label: 'Total Liabilities & Equity',
        propName: 'total_liabilities_and_equity',
        depth: 3,
        isBold: true,
        hide: !expanded.balance_sheet,
      },
      {
        isDivider: true,
        hide: !expanded.balance_sheet,
      },
    ];

    const sectionsConfig = [
      {
        id: SECTION_KEY.KPIS,
        config: kpis,
      },
      {
        id: SECTION_KEY.PROFIT_AND_LOSS,
        config: profitAndLossConfig,
      },
      {
        id: SECTION_KEY.TWELVE_BALANCE,
        config: twelveBalanceConfig,
      },
      {
        id: SECTION_KEY.CASH_FLOW,
        config: cashflowConfig,
      },
      {
        id: SECTION_KEY.BALANCE_SHEET,
        config: balanceSheetConfig,
      },
    ];

    const generateCsvData = () => {
      let csvData = [];

      const headerData = generateTableHeaderData();

      csvData.push(['', ...headerData.map((item) => item.date)]);
      csvData.push(['', ...headerData.map((item) => item.mob)]);
      csvData.push(['', ...headerData.map((item) => item.periodTypeLetter)]);

      sectionsConfig.forEach((section) => {
        section.config.forEach((item) => {
          if (item.isTitle) {
            // csvData.push([item.label]);
            return;
          }

          csvData.push([
            item.label,
            ...(tableData[section.id][item.propName]?.map(
              (item) => item.amount
            ) || []),
          ]);
        });
      });

      return csvData;
    };

    const generateTableHeaderData = () => {
      const row = cloneDeep(tableData.profit_and_loss.cogs);

      const mToday = moment({
        year: moment().year(),
        month: moment().month(),
        day: 1,
      });

      row.forEach((element) => {
        element.date = {
          year: element.date.year,
          month: element.date.month - 1,
          day: element.date.day,
        };
      });

      let headerData = row
        .sort((a, b) => moment(a.date).diff(moment(b.date)))
        .map((item) => {
          const monthDiff = moment(item.date).diff(mToday, 'months');
          return {
            date: moment(item.date).format('MM/YYYY'),
            mob: `MOB.${monthDiff}`,
            periodTypeLetter:
              monthDiff === -1 ? 'A/F' : item.IsPredicted ? 'F' : 'A',
          };
        });

      headerData.unshift('');

      return headerData;
    };

    const TableHeader = () => {
      const tableHeaderData = generateTableHeaderData();

      let thStickyStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 2,
      };

      let firstThStickyStyle = {
        left: 0,
        zIndex: 3,
      };

      return (
        <Thead>
          <Tr>
            {tableHeaderData.map((headerItem, index) => {
              return (
                <Th
                  key={index}
                  fontSize={'15px'}
                  fontWeight={800}
                  bg={'brand.800'}
                  color={'#fff'}
                  {...thStickyStyle}
                  {...(index === 0 ? firstThStickyStyle : {})}
                >
                  {index === 0 ? null : (
                    <VStack spacing={2} align={'end'}>
                      <Text>{headerItem.mob}</Text>
                      <Text>{headerItem.date}</Text>
                      <Text>{headerItem.periodTypeLetter}</Text>
                    </VStack>
                  )}
                </Th>
              );
            })}
          </Tr>
          <Tr>
            <Th {...thStickyStyle}></Th>
          </Tr>
        </Thead>
      );
    };

    const TableBody = () => {
      return (
        <Tbody>
          {sectionsConfig.map((section) => {
            return section.config.map((item, index) => {
              if (item.hide) {
                return null;
              }
              return (
                <TableRow
                  sectionKey={section.id}
                  key={`${section.id}_${index}`}
                  item={item}
                />
              );
            });
          })}
        </Tbody>
      );
    };

    const TableRow = ({ sectionKey, item }) => {
      const {
        label,
        expandId,
        depth,
        propName,
        isTitle,
        isBold,
        isDivider,
        isEmptyRow,
        isPercentage,
        tooltip,
      } = item;
      let depthPadding = depth ? depth * 10 : 0;
      if (!expandId) {
        depthPadding += 20;
      }

      depthPadding += 'px';

      const tdStickyStyle = {
        position: 'sticky',
        left: 0,
        zIndex: 1,
        bg: 'white',
      };

      const tdStyle = {
        borderWidth: 0,
        py: 2,
      };

      const dividerTdStyle = {
        border: 0,
        bg: borderColor,
        h: '1px',
        p: 0,
      };

      const data =
        tableData[sectionKey][propName] ||
        Array.from({ length: numberOfColumns });

      return (
        <>
          {isEmptyRow || isDivider ? (
            <>
              {isDivider && (
                <Tr>
                  {Array.from({ length: numberOfColumns + 1 }).map(
                    (_, index) => (
                      <Td key={index} {...dividerTdStyle}></Td>
                    )
                  )}
                </Tr>
              )}
              {isEmptyRow && (
                <Tr>
                  <Td></Td>
                </Tr>
              )}
            </>
          ) : (
            <Tr fontWeight={isBold || isTitle ? 800 : 400}>
              <Td pl={depthPadding} {...tdStyle} {...tdStickyStyle}>
                <Flex align={'center'}>
                  {expandId && (
                    <Button
                      display={'block'}
                      minW={'20px'}
                      h={'20px'}
                      p={0}
                      bg={'inherit'}
                      _hover={{ bg: 'inherit' }}
                      onClick={() => {
                        setExpanded({
                          ...expanded,
                          [expandId]: !expanded[expandId],
                        });
                      }}
                    >
                      {expanded[expandId] ? '-' : '+'}
                    </Button>
                  )}
                  <Text fontSize={!depth ? '20px' : null}>{label}</Text>
                  {tooltip && <HelperTooltip content={tooltip} />}
                </Flex>
              </Td>
              {data.map((item, index) => {
                let tdBgColor;

                if (sectionKey === SECTION_KEY.KPIS) {
                  tdBgColor =
                    index >= data.length - 12 && !isTitle
                      ? KPIS_CELL_BG_COLOR
                      : '';
                } else {
                  tdBgColor = item?.IsPredicted ? 'secondaryGray.200' : '';
                }

                return (
                  <Td
                    key={index}
                    textAlign={'right'}
                    {...tdStyle}
                    bg={tdBgColor}
                    borderRight={'5px solid #fff'}
                  >
                    {!item ? null : isPercentage ? (
                      <Percentage
                        value={item.amount}
                        maximumFractionDigits={0}
                      />
                    ) : (
                      <Number
                        value={item.amount / 1000}
                        maximumFractionDigits={0}
                        isAccountingFormat={true}
                      />
                    )}

                    {item?.is_spread && '*'}
                  </Td>
                );
              })}
            </Tr>
          )}
        </>
      );
    };

    return (
      <Box>
        <Box mt={6}>
          <TableContainer
            mt={6}
            maxH={'calc(100vh - 200px)'}
            overflowY={'auto'}
          >
            <Table variant={'simple'}>
              <TableHeader />
              <TableBody />
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
);

ResultsTable.propTypes = {
  modelResults: PropTypes.object,
  cohortsRawData: PropTypes.object,
  roasVector: PropTypes.array,
  kpiVectors: PropTypes.object,
};

export default ResultsTable;
