import { calcCashflowModelApi } from 'api/cashflowModelApi';
import Card from 'components/card/Card';
import OverlaySpinnerWrapper from 'components/OverlaySpinner';
import { useRef, useState } from 'react';
import ResultsTable from './ResultsTable';

import { DownloadIcon } from '@chakra-ui/icons';
import { Box, Button, Divider, Flex, useToast } from '@chakra-ui/react';
import { CSVLink } from 'react-csv';
import CashflowModelInputs from './CashflowModelInputs';
import DetailsCsv from './DetailsCsv';
import ResultsKpis from './ResultsKpis';

const CashflowModel = () => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState();
  const [csvData, setCsvData] = useState([]);

  const refModelResultsTable = useRef(null);
  const refModelInputs = useRef(null);

  const toast = useToast();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const _results = await calcCashflowModelApi(data);
      setResults(_results);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to calculate model.',
        status: 'error',
      });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const generateCsvData = () => {
    const modelCsv = refModelResultsTable.current.generateCsvData();
    const inputsCsv = refModelInputs.current.generateCsvData();
    const _csvData = [...modelCsv, [], [], ...inputsCsv];
    setCsvData(_csvData);
  };

  return (
    <Box minW={'container.md'} maxW={'max'}>
      <OverlaySpinnerWrapper show={loading} isFixed={true}>
        <Card>
          <CashflowModelInputs
            onSubmit={onSubmit}
            modelResults={results?.items}
            roasVector={results?.roas_100_avg_6}
            onCustomerChange={() => setResults(null)}
            ref={refModelInputs}
          />

          {results && (
            <Box mt={10}>
              <Divider borderColor={'secondaryGray.900'} />
              <Box mt={10}>
                <Box mt={5}>
                  <ResultsKpis kpis={results.kpis} />
                </Box>
                <Box mt={10}>
                  <ResultsTable
                    modelResults={results.items}
                    cohortsRawData={results.cohorts}
                    roasVector={results.roas_100_avg_6}
                    kpiVectors={results.kpis.vectors}
                    ref={refModelResultsTable}
                  />
                </Box>
                <Box mt={10}>
                  <Flex mt={10} gap={5}>
                    <Button variant={'outline'} leftIcon={<DownloadIcon />}>
                      <CSVLink
                        data={csvData}
                        filename="cashflow-model.csv"
                        onClick={generateCsvData}
                        asyncOnClick={true}
                      >
                        Download CSV
                      </CSVLink>
                    </Button>
                    <DetailsCsv
                      cohortsRawData={results.cohorts}
                      roasVector={results.roas_100_avg_6}
                    />
                  </Flex>
                </Box>
              </Box>
            </Box>
          )}
        </Card>
      </OverlaySpinnerWrapper>
    </Box>
  );
};

export default CashflowModel;
