import { Box, Flex } from '@chakra-ui/react';
import KpiCard from 'components/kpi/KpiCard';
import { numberFormat, percentageFormat } from 'components/Number';
import PropTypes from 'prop-types';

const ResultsKpis = ({ kpis }) => {
  const config = [
    {
      label: 'Duration',
      propName: 'duration',
      format: (value) => numberFormat(value, { maximumFractionDigits: 2 }),
    },
    {
      label: 'XIRR',
      propName: 'xirr',
      format: (value) => percentageFormat(value, { maximumFractionDigits: 2 }),
    },
    {
      label: 'Max Exposure',
      propName: 'max_exposure',
      format: (value) =>
        numberFormat(value, { maximumFractionDigits: 2, isCompact: true }),
    },
    {
      label: 'Runoff',
      propName: 'runoff',
      valueLabel: 'months',
      format: (value) =>
        numberFormat(value, { maximumFractionDigits: 2, isCompact: true }),
    },
    {
      label: 'Runoff Total Revenue',
      propName: 'salesToRunoff',
      format: (value) =>
        numberFormat(value, { maximumFractionDigits: 2, isCompact: true }),
    },
    {
      label: 'Runway',
      propName: 'runway',
      valueLabel: 'months',
      format: (value) =>
        numberFormat(value, { maximumFractionDigits: 2, isCompact: true }),
    },
    {
      label: 'Runway Total Revenue',
      propName: 'salesToRunway',
      format: (value) =>
        numberFormat(value, { maximumFractionDigits: 2, isCompact: true }),
    },
  ];

  return (
    <Flex gap={9} w={'max'}>
      {config.map((item, index) => {
        const { value, requires_more_data } = kpis[item.propName] || {};

        return (
          <Box minW={120} key={index}>
            <KpiCard
              title={requires_more_data ? item.label + '*' : item.label}
              minW={'max'}
              items={[
                {
                  value: value,
                  format: item.format,
                  label: item.valueLabel,
                },
              ]}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

ResultsKpis.propTypes = {
  kpis: PropTypes.object,
};

export default ResultsKpis;
